import './App.css';
import LinksPage from "./pages/links/links"

function App() {
  return (
    <div className="App">
      <LinksPage/>
    </div>
  );
}

export default App;
